<template>
  <div ><div class="title">联系我们</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-11-07 17:57:00
  </div><div data-v-0ab10f90="" style="font-size: 14px;"><p>供正品&nbsp; 态度价！</p>
    <p>我们共同打造温暖亲切的生态体系</p>
    <p>&nbsp;</p> 
    <p>服务热线：</p>
    <p>&nbsp; &nbsp; &nbsp; &nbsp;业务咨询：<strong> 业务咨询：{{ $tp().OPEN_PHONE }}</strong></p>
    <p>&nbsp; &nbsp; &nbsp; &nbsp;售前：<strong>{{ $tp().OPEN_PHONE }}</strong></p>

    <p><span class="g">&nbsp; &nbsp; &nbsp; &nbsp;售后：<strong>{{ $tp().OPEN_PHONE }} &nbsp; &nbsp;&nbsp;</strong>(工作时间竭诚为您服务)</span></p>
    <p>服务时间：周一～周五  09：30－18：30</p>
    <p>电子邮箱：{{ $tp().OPEN_EMAIL }}</p>
    <p>平台留言：您可以点击“平台留言”将您的意见和建议提给我们</p>
    <p>大陆地址：{{ $tp().OPEN_ADDRESS }}</p>
  </div></div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:59
 * @version 1.0
 * @description
 */
export default {
  name: 'ContactUs'
}
</script>

<style scoped>

</style>
